import React from "react"
import { graphql } from "gatsby"

import Layout from "components/Layout"
import Seo from "components/Seo"
import Breadcrumbs from "components/Breadcrumbs"

import { ForgotPasswordConfirmation } from "page_components/login-register"

const LoginPage = ({ data, location }) => {
  return (
    <Layout
      location={location}
      mainBackground={
        data?.allWpPage?.nodes[0]?.featuredImage?.node?.localFile?.publicURL
      }
    >
      <Breadcrumbs title="Password reset confirmation" />
      <ForgotPasswordConfirmation />
    </Layout>
  )
}

export const Head = () => <Seo title="Password reset confirmation" noIndex />

export const query = graphql`
  query {
    allWpPage(filter: { id: { eq: "cG9zdDoxMzIyOQ==" } }) {
      nodes {
        featuredImage {
          node {
            localFile {
              publicURL
            }
          }
        }
      }
    }
  }
`

export default LoginPage
